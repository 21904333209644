import React, { Component } from "react"
import Layout from "../components/layout"
import HeaderContentOffer from "../components/HeaderContentOffer"
import BackgroundHero from "../components/BackgroundHero"
import HowTo from "../components/howTo"
import ServicesOffer from "../components/ServicesOffer"
import Benefits from "../components/Benefits"
import CodeIcon from "../assets/svgIcon/code.svg"
import ComputerChartIcon from "../assets/svgIcon/computerChart.svg"
import NetworkIcon from "../assets/svgIcon/network.svg"
import ToolsMobileIcon from "../assets/svgIcon/toolsMobile.svg"
import WordGlassIcon from "../assets/svgIcon/wordGlass.svg"
import GlassIcon from "../assets/svgIcon/glass.svg"
import ClipIcon from "../assets/svgIcon/clip.svg"
import PageSpeedIcon from "../assets/svgIcon/pageSpeed.svg"
import ToolsIcon from "../assets/svgIcon/tools.svg"
import OfferIconServices from "../components/OfferIconServices"
import OfferIconInner from "../components/OfferIconInner"
import OptymalizationIcon from "../assets/svgIcon/optymalizacja.svg"
import SEO from "../components/seo"
import OfferIcon from "../components/OfferIcon"
import PriceSeo from "../components/PriceSeo"


class PozycjonowanieStron extends Component {
  render() {
    return (
      <Layout footerDesc="Uzyskaj większy ruch. Pozyskaj więcej klientów. Sprzedawaj więcej rzeczy. CodersBros z siedzibą w
      Kielcach, Polska działa dla firm każdej wielkości.">
        <SEO title="Pozycjonowanie stron internetowych Kielce | Optymalizacja"
             description="Najlepsza firma pozycjonująca strony internetowe w Kelcach.
             Zapewniamy  firmom z Kielc i nie tylko usługi SEO. Oferujemy optymalizację stron internetowych
             , badanie słów kluczowych itp, aby Twoja firma znalazła się wyżej w wynikach wyszukiwania. Porozmawiajmy!"
        />
        <HeaderContentOffer title="Pozycjonowanie Stron Internetowych"
                            subTitle="Bądź na szczycie w Google dzięki CodersBros "
                            desc="CodersBros to jedna z wiodących profesjonalnych firm zajmujących się pozycjonowaniem stron internetowych w Kielcach. Nasz zespół jest pasjonatem dostarczania najlepszego zakresu strategii marketingu internetowego, które pomogą Twojej firmie osiągnąć sukces w cyfrowym świecie."/>
        <BackgroundHero img="seo"/>
        <HowTo title="Co to jest pozycjonowanie stron (SEO - Search Engine Optimization)?"
               smallTitle="jak działamy?"
               desc="Zasadniczo SEO to proces pozyskiwania ruchu z bezpłatnych wyników wyszukiwania w wyszukiwarkach. Poprawia widoczność online. Im bardziej profesjonalna optymalizacja strony tym wyższa pozycja uzyskana na listach wyników wyszukiwania. Jest to szczególnie ważne, ponieważ większość użytkowników internetu zwraca uwagę na pierwsze dziesięć pozycji w rankingu, a tak jak w każdej płaszczyźnie biznesowej, dążymy do tego, aby wyprzedzić swoją konkurencję."
        >
        </HowTo>
        <Benefits title="Czy pozycjonowanie srony internetowej opłaca się dla Twojej firmy?"
                  smallTitle="Pozycjownie - po co?"
                  desc="Zdobycie pierwszego miejsca robi różnicę. Wynik numer 1 w bezpłatnych wynikach wyszukiwania Google ma średni współczynnik klikalności (CTR) wynoszący 31,7%, a wynik organiczny nr 1 jest 10 razy bardziej narażony na kliknięcie w porównaniu ze stroną na miejscu 10."
                  benefitsArr={["72% szukających wybierze bezpłatne wyniki wśród płatnych wpisów w wynikach wyszukiwania Google. ( Moz )",
                    "Organiczny CTR dla pozycji 7-10 jest prawie taki sam. ( Backlinko )",
                    "W większości przypadków optymalizacja stron internetowych  wyprzedza  płatne reklamy i  marketing w mediach społecznościowych w generowaniu potencjalnych klientów. ",
                    "Bezpłatne wyszukiwanie odpowiada za 53% całego ruchu w witrynie. ( Wyszukiwarka Land )",
                    "Google kontroluje 98,86% rynku wyszukiwania. ( StatCounter )",
                    "Bezpłatne wyszukiwanie odpowiada za 53% całego ruchu w witrynie. ( Wyszukiwarka Land )",
                    "93% użytkowników nie przewija za pierwszą stronę wyników wyszukiwania. ( Moz )",
                    "78% konsumentów woli uczyć się o marce poprzez artykuły niż reklamy. ( CoSchedule )",
                    "55% marketerów twierdzi, że tworzenie treści na blogach jest ich najwyższym priorytetem marketingowym. ( CoSchedule )"
                  ]}
        />
        <HowTo title="Jak pozycjonujemy stronę internetową w CodersBros?"
               smallTitle="jak działamy?"
               desc="Zasadniczo SEO to proces pozyskiwania ruchu z bezpłatnych wyników wyszukiwania w wyszukiwarkach. Poprawia widoczność online. Im bardziej profesjonalna optymalizacja strony tym wyższa pozycja uzyskana na listach wyników wyszukiwania. Jest to szczególnie ważne, ponieważ większość użytkowników internetu zwraca uwagę na pierwsze dziesięć pozycji w rankingu, a tak jak w każdej płaszczyźnie biznesowej, dążymy do tego, aby wyprzedzić swoją konkurencję."
        >
          <div className="howTo__inner paddingInside seo">
            <OfferIcon title="1. Analiza słów kluczowych"
                       desc="Pierwszy etap naszego procesu obejmuje analizę słów kluczowych, którymi jesteś zainteresowany i słów kluczowych, na które kierują konkurenci. Eksperci zajmujący się pozycjonowaniem stron internetowych zgromadzą te dane, a następnie użyją określonych narzędzi do optymalizacji wyszukiwania w celu wygenerowania dalszych opcji słów kluczowych. Ostatecznym wynikiem będzie lista potencjalnych segmentów słów kluczowych do wyboru.">
              <GlassIcon/>
            </OfferIcon>
            <OfferIcon title="2. Optymalizacja strony internetowej"
                       desc="Optymalizacja stron internetowych jest jedną z naszych głównych specjalizacji, więc będziesz wiedział, że Twoja strona jest w dobrych rękach. Twoja optymalizacja kodu wymaga najpierw naprawy wszystkiego, co jest zepsute (jest to ważna część procesu, ponieważ pozwala nam upewnić się, że nic nie jest technicznie złe w witrynie). Następnie zoptymalizujemy tytuły stron, opisy i meta słowa kluczowe poprawimy H1, H2 rozszerzamy lub zmieniamy treści na Twojej stronie internetowej. Przyglądamy się architekturze strony WWW i badamy poprawność  wewnętrznej struktury linków. Dołożymy również starań, aby wyszukiwarki mogły indeksować treść, żadne strony nie były blokowane, wszystkie linki były śledzone, a wszystko na zapleczu jest ogólnie w doskonałym stanie do indeksowania wyszukiwarek. Przyjrzymy się również zaawansowanym strategiom, takim jak Schema.org, obrazy, filmy, mapy witryn, plik robots.txt i inne.">
            <ToolsIcon/>
            </OfferIcon>
            <OfferIcon title="3. Prędkość wczytywania strony WWW"
                       desc="Oddzielnie sprawdzamy prędkość wczytywania strony internetowej. Nasi specjaliści od pozycjonowania stron internetowych wraz programistami zbierają dane z Page Speed Insight oraz Lighthouse, GT Metrix itp. Następnie optymalizujemy twoją witrynę zgodnie z sugestiami powyższych raportów. Dokładamy wszelkich starań aby Twoja strona WWW wczytywała się z maksymalną prędkością – pamiętaj szybkość ma znaczenie!">
              <PageSpeedIcon/>
            </OfferIcon>
            <OfferIcon title="4. Zaawansowane trategie optymalizacji"
                       desc="Po upewnieniu się, że te krytyczne elementy zostały objęte, przechodzimy do zaawansowanych strategii optymalizacji pod kątem wyszukiwarek. W tej fazie dokonamy ostatecznego przeglądu struktury technicznej Twojej witryny, a następnie zaproponujemy nowe formy rozwoju ruchu związanego z wyszukiwaniem. Ponadto faza ta oznacza początek znacznego budowania zewnętrznych linków. Należy zauważyć, że zewnętrzne budowanie linków może mieć miejsce w różnym czasie, wszystko to będzie oparte na strategii budowania linków, którą tworzy specjalista  do spraw pozycjonowania stron internetowych, która będzie zależała od celów kampanii marketingowej. ">
            <ClipIcon/>
            </OfferIcon>
          </div>
        </HowTo>
        <ServicesOffer title="Nasze usługi pozycjonowania stron internetowych w Kielcach"
                       smallTitle="co oferujemy?">
          <OfferIconServices>
            <WordGlassIcon/>
            <OfferIconInner
              title="Badanie słów kluczowych"
              desc="Znajdujemy najbardziej wydajne słowa lub frazy kluczowe oraz sporządzamy ich listę według priorytetowośći i preferencji klienta."
            />
          </OfferIconServices>

          <OfferIconServices>
            <CodeIcon/>
            <OfferIconInner
              title="Audyt witryny"
              desc="Dokładnie przeszukujemy Twoją witrynę pod względem SEO, w celu odkrycia błędów, którę mogą mieć niekorzystny wpływ na pozycję w rankingu wyszukiwarek oraz sporządzamy listę poprawek do wykonania."
            />
          </OfferIconServices>
          <OfferIconServices>
            <OptymalizationIcon/>
            <OfferIconInner
              title="Optymalizacja pod kątem szybkości"
              desc="Według najnowszych badań, szybkość ładowania strony jest jednym z głównych czynników wpływających na jej pozycje. Oferujemy Tobie optymalizację strony lub sklepu internetowego według wytycznych topowych testów szybkościowych takich jak Google Page Speed Insight, GTMetrix, itp."
            />
          </OfferIconServices>
          <OfferIconServices>
            <ComputerChartIcon/>
            <OfferIconInner
              title="Optymalizacja pod kątem wyszukiwarek"
              desc="Nasze strategie i taktyki optymalizacji pod kątem wyszukiwarek (SEO) mogą zwiększyć widoczność bezpłatnych wyników wyszukiwania w Google i pomóc w pozyskiwaniu potencjalnych klientów w Twojej firmie."
            />
          </OfferIconServices>
          <OfferIconServices>
            <ToolsMobileIcon/>
            <OfferIconInner
              title="Optymalizacja mobilna"
              desc="Zapewniamy  ekspercką optymalizację mobilną z responsywnym projektowaniem stron internetowych, kierowaniem reklam oraz lepszą widocznością organiczną, lokalną, krajową ."
            />
          </OfferIconServices>
          <OfferIconServices>
            <NetworkIcon/>
            <div className="offerIcon__inner">
              <h3 className="offerIcon__title">E-commerce i rozwój sieci</h3>
              <p className="blank">Popraw pozycję swojego sklepu dzięki naszemu eksperckiemu wsparciu w pozycjonowaniu
                sklepów www. Nasz zespół ma duże doświadczenie w nopCommerce .</p>
            </div>
          </OfferIconServices>
        </ServicesOffer>
        <PriceSeo title="Ile kosztuje pozycjonowanie stron w CodersBros." smallTitle="ile to kosztuje?"/>
        <Benefits title="Korzyści dla Ciebie ze współpracy z CodersBros"
                  smallTitle="dlaczego my?"
                  benefitsArr={["Zoptymalizowana strona WWW nie martwisz się czy wszystko jest ok.",
                    "Jesteśmy dla Ciebie pierwszym sprzymierzeńcem w biznesie Twoje cele są naszymi celami",
                    "Masz więcej czasu na rozwój i zarabianie pieniędzy",
                    "Szerokie analizy i raporty pozwolą Ci na śledzenie i korekty obranej strategii.",
                    "Dbamy aby Twoje wydane pieniądze przynosiły zwrot z inwestycji."
                  ]}
        />
      </Layout>
    )
  }
}


export default PozycjonowanieStron
