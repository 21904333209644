import React from "react"
import SmallTitle from "./smallTitle"
import BigTitle from "./BigTitle"
import Button from "./Button"

function PriceSeo({smallTitle, title}) {
  return (
    <section className="seoPrice">
      <SmallTitle title={smallTitle}/>
      <BigTitle title={title}/>
      <p className="blank">
        Jesteś zainteresowany kampanią SEO, a może już robisz pozycjonowanie stron i zastanawiasz się, czy jest coś lepszego. Tak czy inaczej, zastanawiasz się, ile kosztuje SEO - lub powinno kosztować. To zasadnicze  pytanie i uprzedzę cię , że nie ma jednej odpowiedzi, ale zbadanie rodzajów struktur kosztów SEO i wartości usług SEO może pomóc ci lepiej zrozumieć najlepszy sposób zapłaty za pozycjonowanie stron i tak aby uzyskać najwyższy zwrot z każdej zainwestowanej złotówki .
      </p>
      <h4>Opłaty za pozycjonowanie stron lub sklepów internetowych zależą od wielu czynników:</h4>
      <ul>
        <li>Czy pozycjonujesz stronę lokalnie czy prowadzisz kampanię ogólnopolską?</li>
        <li>Ile słów kluczowych wybrałeś?</li>
        <li>W jakim stanie jest Twoja strona internetowa lub sklep internetowy, co jest do poprawy, a może wszystko jest ok.</li>
        <li>Jaka jest prędkość wczytywania Twojej strony i czy struktura kodu jest poprawna</li>
        <li>Czy treści na Twojej stronie WWW są bogate i unikalne </li>
        <li>Czy struktura linków wewnętrznych jest poprawna i czy prowadzą linki zewnętrzne do Twojej witryny</li>
      </ul>
      <p className="blank">
        CodersBros nie generalizuje oferty, nie płacisz za to co nie robimy na twojej stronie internetowej. Każdy klient jest dla nas najważniejszy i dlatego przygotowujemy ofertę szytą na miarę z uwzględnieniem Twojego budżetu.
      </p>
      <h3>Jesteśmy gotowi uwolnić Twój prawdziwy potencjał wyszukiwania</h3>
      <Button link="/#kontakt" text="zapytaj o oferte"/>
    </section>

  )
}

export default PriceSeo